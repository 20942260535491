import React, { useEffect, useState, useRef } from "react"
import "../static/style/pageStyle/aws-consulting-partner.scss"
import { graphql, useStaticQuery } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import Cards from "../components/cards"
import AOS from "aos"
import "aos/dist/aos.css"
/*! purgecss start ignore */ import "aos/src/sass/aos.scss" /*! purgecss end ignore */
import Underline from "../components/underline"
import CardsMobile from "../components/cards-mobile"
import UnderlineButton from '../components/underlineButton'
import Layout from "../components/Layout"
import awsAward from "../static/images/aws-page/Banner.png"
import awsAwardmobile from "../static/images/aws-page/BannerMobile.png"
import devops from '../static/images/devops.svg';
import dataIcon from "../static/images/aws-page/data.svg"
// import awsIcons from "../static/images/aws-page/1.svg"
// import awsIcon1 from "../static/images/aws-page/image_1.png"
// import awsIcon2 from "../static/images/aws-page/image_2.png"
// import awsIcon3 from "../static/images/aws-page/image_3.png"
// import awsIcon4 from "../static/images/aws-page/image_4.png"
// import awsIcon5 from "../static/images/aws-page/image_5.png"
// import awsIcon6 from "../static/images/aws-page/image_6.png"
// import awsIcon7 from "../static/images/aws-page/image_7.png"
// import awsIcon8 from "../static/images/aws-page/image_8.png"
// import awsIcon9 from "../static/images/aws-page/image_9.png"
// import awsIcon10 from "../static/images/aws-page/image_10.png"

import image1 from "../static/images/partner/image.png"
import image2 from "../static/images/partner/image2.png"
import image3 from "../static/images/partner/image3.png"
import image4 from "../static/images/partner/image4.png"
import image5 from "../static/images/partner/image5.png"
import image6 from "../static/images/partner/image6.png"
import image7 from "../static/images/partner/image7.png"
import image8 from "../static/images/partner/image8.png"
import image9 from "../static/images/partner/image9.png"
import image10 from "../static/images/partner/image10.png"
import image11 from "../static/images/partner/image11.png"
import image12 from "../static/images/partner/image12.png"
import BlackBtn from "../components/black-btn"
import CaseStudies from "../components/caseStudies"
// import { FetchedAwsPartnerPageData } from "../services/helperFunctions"
import { FetchedAwsPartnerPageData } from "../services/helperFunctions"
import Parser from "html-react-parser"
import { Link } from "gatsby"
import BannerHeader from "../components/bannerHeader"

const AwsConsultingPartner = props => {
  const data = useStaticQuery(
    graphql`
      {
        allAwsPage {
          nodes {
            awsPage {
              journey {
                attributes {
                  dynamicCmp {
                    Impact
                    Problem
                    Solution
                    cardHeading
                    content
                    iconUrl
                    id
                    imageUrl
                    statement
                    story
                  }
                  header
                  paragraph
                  subHeader
                  cmpName
                }
              }
            }
          }
        }
      }
    `
  )
  // console.log(data?.allAwsPage?.nodes[0]?.awsPage?.journey, "adasdasd")
  const {
    settings,
    testimonialSlider,
    cardMap,
    cardData,
    cardClient,
    sections,
    cards,
    iconArr,
    awsSlider,
    tabs,
    allCaseStudy,
    awsIcon
  } = FetchedAwsPartnerPageData(data?.allAwsPage?.nodes[0]?.awsPage?.journey)
  const [isNavBarOpen, setIsNavBarOpen] = useState(true)
  const [activeTab, setActiveTab] = useState(0)
  const [mobileActiveTab, setMobileActiveTab] = useState(false)

  const [dActiveTab, setDActiveTab] = useState(true)
  const [mdActiveTab, setMDActiveTab] = useState(false)
  const handleClick = header => {
    setActiveTab(header)
    setDActiveTab(!dActiveTab)
    if (activeTab === header) {
      setDActiveTab(!dActiveTab)
    }
    // console.log(activeTab, "as")
  }
  const handleClickMobile = header => {
    // console.log(header, "header")
    setMobileActiveTab(header)
    setMDActiveTab(!mdActiveTab)
    if (mobileActiveTab === header) {
      setMDActiveTab(!mdActiveTab)
    }
  }
  const imgAlt = "enterprise ai"
  useEffect(() => {
    if (props?.location?.state) {
      const id = Object.values(props?.location?.state)
        .filter(
          (value, index) =>
            typeof value === "string" &&
            index !== Object.keys(props?.location?.state).length - 1
        )
        .join("")
      if (id) {
        const partnersLocation = document.getElementById(id)
        if (partnersLocation) {
          partnersLocation.scrollIntoView({ behavior: "smooth" })
        }
      }
    }
  }, [props?.location?.state])


  return (
      <div
        className={`aws-consulting-partner ${isNavBarOpen ? "" : "no-scroll"}`}
      >
        <div className="page-content">
          {/* -------- Banner start -------- */}
          <div className="banner-wrapper">
            
            <div className="page-wrapper">
              <div className="page-banner">
                <div className="banner-header">
                  <BannerHeader headingType={"bannerTitle"} header={sections[0]["section-00"].paragraph} class="heading-md" line={"two-line"} />
                </div>
                <div className="image-container-header desktop-responsive-class">
                  <div className="banner-image">
                    <img src={awsAward} alt={imgAlt}/>
                  </div>
                </div>
                <div className="image-container-header mobile-responsive-class">
                  <div className="banner-image">
                      <img src={awsAwardmobile} alt={imgAlt}/>
                    </div>
                  </div>
                <div className="banner-header banner-header-link">
                  <Link to='https://aws.amazon.com/blogs/apn/say-hello-to-154-aws-competency-service-delivery-service-ready-and-msp-partners-added-or-renewed-in-april/' target="_blank" name="blog"><UnderlineButton text={"Click Here To Read More"} /></Link> 
                </div>
              </div>
            </div>
          </div>
          {/* -------- Banner end -------- */}

          <div className="clear"></div>
          <Underline />
          {/* --------devops section -------- */}
          <div className="devops-section">
            <div className="page-wrapper">
              <div className="content">
                <div className="aws-cons-left-details">
                  <BannerHeader header={'With AWS We Achieved Our Competencies in <br/>Data and Analytics, Machine Learning and DevOps.'} class="heading-md" line={"two-line"} />
                </div>
                <div className="aws-cons-right-details">
                  <div className="devops-section-img">
                    <img src={devops} alt={imgAlt}/>
                  </div>
                  <div className="devops-btn">
                    <Link to="/aws-devops" name="devops">
                      <BlackBtn text = {'Click To Know More'}/>
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="clear"></div>
          <Underline />
          {/* --------devops section -------- */}
          <div className="section-01">
            <div className="page-wrapper">
              <div className="content">
                <div className="aws-cons-left-details">
                  
                  <BannerHeader header={sections[1]["section-01"].header} class="heading-md" line={"two-line"} />
                  <p>{Parser(sections[1]["section-01"].paragraph)}</p>
                </div>
                <div className="aws-cons-right-details">
                  <img src={`${process.env.STRAPI_URL +sections[1]["section-01"].dynamicCmp[0].imageUrl}`} alt={imgAlt}/>
                </div>
              </div>
            </div>
          </div>

          <div className="clear"></div>
          <Underline />
          {/* --------Unleash the Power of Data in Cloud end -------- */}

          {/* --------you are in good hands start -------- */}
          <div className="section-02">
            <div className="page-wrapper">
              <div className="content">
                <BannerHeader header={sections[2]["section-02"].header} class="heading-md" line={"single-line"} />
                <div className="aws-partner-list">
                  {awsIcon.map((val,idx)=>(
                    <div className="aws-icons" key={idx}>
                    <img
                      src={`${process.env.STRAPI_URL +val.icon}`} alt={imgAlt}
                    />
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* --------you are in good hands start -------- */}

          <Underline />

          {/* --------section 01 start -------- */}
          <div className="section-03">
            <div className="page-wrapper ">
              <div className="main-container">
                <BannerHeader header={sections[3]["section-03"].header} class="heading-lg" line={"two-line"} />
                <p>{Parser(sections[3]["section-03"].paragraph)}</p>
                <div className="aws-content">
                  <BannerHeader header={sections[3]["section-03"].subHeader} class="heading-md" line={"single-line"} />
                  <div className="customer-data-solution-cards">
                    {cards.map((val, idx) => (
                      <div className="data-solution-card">
                        <div className="data-solution-card-icon">
                          <div className="data-icon">
                            <img src={`${process.env.STRAPI_URL +val.iconUrl}`} alt={imgAlt}/>
                          </div>
                          <p>{Parser(val.cardHeading)}</p>
                        </div>
                        <div className="data-solution-card-details">
                          <p>{Parser(val.content)}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* --------section 01 end -------- */}

          <Underline />
          {/* --------section 04 start building our data -------- */}

          <div className="section-04">
            <div className="page-wrapper ">
              <div className="main-container">
                <div>
                  <BannerHeader header={sections[4]["section-04"].header} class="heading-lg" line={"two-line"} />
                </div>
                <div className="padding-t-30 amazon-slider">
                  <div className="sliderBox" aria-label="slider">
                    {/* <Slider {...settings}> */}
                      <div className="itembox">
                        <div className="all-cards">
                          <div className="aws-card-logo">
                            <img
                              src={image1}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                              
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image2}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image3}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image4}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image5}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image6}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image7}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image8}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image9}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image10}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image11}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={image12}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="itembox">
                      <div className="all-cards">
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon1}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon2}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon3}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon4}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon5}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon6}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon7}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon8}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon9}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon10}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon1}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                          <div className="aws-card-logo">
                            <img
                              src={awsIcon2}
                              alt="Arch AmazonAthena"
                              className="lazyloaded"
                            />
                          </div>
                        </div>
                      </div> */}
                    {/* </Slider> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* --------section 04 end -------- */}

          <Underline />

          {/* --------section 05 start  Purpose built for Financial Services-------- */}
          <div className="section-05">
            <div className="page-wrapper ">
              <div className="main-container">
                <div className="content">
                  <div className="aws-databases">
                    <div className="sliderBox" aria-label="financial slider">
                      <Slider {...settings}>
                        {awsSlider.map((val, idx) => (
                          <div className="itembox  aws-service" key={idx}>
                            <div className=" aws-database-details">
                              <h2 className="heading-md">
                                {val.cardHeading}
                              </h2>
                              {/* <BannerHeader header={val.cardHeading} class="heading-md" line={"single-line"} /> */}
                              <div className="text-center">
                                <div className={`aws-img ${idx===2?"aws-glue":""}`}>
                                  <img
                                    src={`${process.env.STRAPI_URL +val.iconUrl}`}
                                    alt="being lumiq"
                                    className="lazyloaded"
                                  />
                                </div>
                              </div>
                              <p>
                                {val.content}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* --------section 05 end -------- */}

          <Underline />

          {/* --------section 04 start Our clients trust us.  -------- */}
          <div className="section-06">
            <div className="page-wrapper">
              <div className="main-container" id="caseStudies">
                <BannerHeader header={'Case Studies'} class="heading-lg" line={"single-line"} />
                <div className="aws-case-studies">
                  <div className="aws-left-details">
                    <ul>
                      {tabs.map((tab, idx) => (
                        <>
                          <li
                            onClick={() => handleClick(tab.id)}
                            className={`${
                              activeTab === tab.id ? "active-tab-black" : ""
                            }`}
                          >
                            <span>{`${idx + 1}`}</span>
                            <div>
                              <p>{tab.label}</p>
                            </div>
                          </li>
                          <div className="case-studies-mobile-resp">
                            {tab.id === activeTab && dActiveTab && (
                              <CaseStudies
                                data={allCaseStudy[activeTab]}
                                id={activeTab}
                              />
                            )}
                          </div>
                        </>
                      ))}
                    </ul>
                  </div>
                  <div className="aws-left-details-mobile">
                    <ul>
                      {tabs.map((tab, idx) => (
                        <>
                          <li
                            onClick={() => handleClickMobile(tab.id)}
                            className={`${
                              mobileActiveTab === tab.id &&
                              mdActiveTab &&
                              "active-tab-black"
                            }`}
                          >
                            <div>
                              <p>{idx + 1}</p>
                              <p>{tab.label}</p>
                            </div>
                            {!(mdActiveTab && mobileActiveTab === tab.id) && (
                              <p className="read-more">Read More</p>
                            )}
                          </li>
                          <div
                            className="case-studies-mobile-resp"
                            onClick={() => handleClickMobile(tab.id)}
                          >
                            {tab.id === mobileActiveTab && mdActiveTab && (
                              <CaseStudies
                                data={allCaseStudy[mobileActiveTab]}
                                id={mobileActiveTab}
                              />
                            )}
                          </div>
                        </>
                      ))}
                    </ul>
                  </div>
                  <div className="aws-right-details">
                    {tabs.map(
                      tab =>
                        tab.id === activeTab && (
                          <CaseStudies
                            data={allCaseStudy[activeTab]}
                            id={activeTab}
                          />
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Underline />

          {/* --------section 04 end -------- */}
          {/* --------section 04 start Our clients trust us.  -------- */}
          <div className="section-07">
            <div className="page-wrapper">
              <div className="main-container">
                <BannerHeader header={'Our Success Stories'} class="heading-lg" line={"single-line"} />
                <div className="card-container ">
                  <div className="testimonialDesktop" aria-label="financial slider">
                    <Slider {...testimonialSlider}>
                      {cardMap.map(i => {
                        return <Cards key={i} cardData={cardData[i]} cardClient={cardClient[i]}/>
                      })}
                    </Slider>
                  </div>
                </div>
                <div className="card-container-mobile ">
                  <div className="testimonialDesktop">
                    {cardMap.map(i => {
                      return <CardsMobile  key={i} cardData={cardData[i]} cardClient={cardClient[i]}/>
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* --------section 04 end -------- */}

          <Underline />

          <div className="section-08">
            <div className="page-wrapper">
              <div className="content">
                <h3 className="heading-md">
                  {sections[8]['section-08'].header}
                </h3>
                <div>
                  <Link to='/careers' state={'applyForAJob'} name="career"><BlackBtn text={"Work With Us"} /></Link>
                </div>
              </div>
            </div>
          </div>
          <Underline />
        </div>
      </div>
  )
}

export default AwsConsultingPartner
